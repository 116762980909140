<template>
  <div>
    <el-form ref="form" :model="formSearch" label-width="6rem" label-position="left">
      <el-row
        type="flex"
        align="top"
        justify="space-between"
        style="margin-bottom: 2%"
        class="search-wrap"
      >
        <el-col :span="16" style="display: flex">
          <el-form-item label="分组名称">
            <el-input v-model="formSearch.name"></el-input>
          </el-form-item>
          <div style="margin-left: 2%">
            <el-button class="fill-btn" @click="search(formSearch)">搜索</el-button>
            <el-button class="un-fill-btn" @click="resetForm(formSearch)">重置</el-button>
          </div>
        </el-col>
        <el-col :span="8" style="display: flex; justify-content: flex-end">
          <el-button class="un-fill-btn" @click="addDialog = true">创建分组</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table
      :data="tableData"
      style="width: calc(100% - 2px)"
      height="70vh"
      row-class-name="tableRowClassName"
      v-loading="loadingShow"
    >
      <el-table-column type="index" label="序号" align="center" width="100px">
      </el-table-column>
      <el-table-column prop="name" label="名称" align="center">
      </el-table-column>
      <el-table-column prop="sort" label="排序" align="center">
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <svg-icon icon-class="edit" @click="edit(scope.row)"></svg-icon>
          <svg-icon icon-class="delete" @click="deleteDialog = true;deleteId = scope.row.id;deleteInfo=scope.row.name"></svg-icon>
        </template>
      </el-table-column>
    </el-table>

    <!-- <div class="pagination-wrap">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="1000"
      >
      </el-pagination>
    </div> -->
    <el-dialog :visible.sync="editDialog" width="564px">
      <el-form ref="editForm" :model="editForm" :rules="editFormRules">
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="editForm.name" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="分组排序" prop="sort">
          <el-input v-model="editForm.sort" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="分组备注">
          <el-input type="textarea" rows="6" v-model="editForm.remarks" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item>
            <div style="float:right;padding-top:70px">
                <el-button class="fill-btn" @click="submitEdit" :loading="editLoading">提交</el-button>
                <el-button class="un-fill-btn" @click="resetForm(editForm)">重置</el-button>
            </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="addDialog" width="564px">
      <el-form ref="addForm" :model="addForm" :rules="addFormRules">
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="addForm.name" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item label="分组排序" prop="sort">
          <el-input v-model="addForm.sort" style="width: 100%"></el-input>
        </el-form-item>
         <el-form-item label="分组备注">
          <el-input type="textarea" rows="6" v-model="addForm.remarks" style="width: 100%"></el-input>
        </el-form-item>
        <el-form-item>
            <div style="float:right;padding-top:70px">
                <el-button class="fill-btn" @click="createGroup" :loading="editLoading">提交</el-button>
                <el-button class="un-fill-btn" @click="resetForm(addForm)">重置</el-button>
            </div>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="deleteDialog" title="删除分组">
      <div class="freed-title">
        <i class="el-icon-info"></i>确定要 删除 分组【{{deleteInfo}}】吗？
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="un-fill-btn" @click="deleteDialog = false;">取 消</el-button>
        <el-button class="fill-btn" type="primary" @click="deleteGroup">删 除</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {groupList,groupEdit,groupAdd,groupDelete} from '@/api/index'
import Fuse from "fuse.js";
import {validSort} from '@/utils/validate'
import {mapGetters} from 'vuex'
export default {
  data() {
    const validateName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error("请输入名称"));
      } else {
        callback();
      }
    };
    const validateSort = (rule, value, callback) => {
      if (value === '') {
        callback(new Error("请输入排序"));
      }else if(!validSort(value)){
        callback(new Error("请输入数字"));
      } else {
        callback();
      }
    };
    return {
      formSearch:{
        name: ''
      },
      loadingShow: false,
      tableData: [],
      editDialog: false,
      addDialog: false,
      addForm:{
        name: "",
        sort: "0",
        remarks: "",
      },
      editForm: {
       id: '',
       name: '',
       sort: '',
       remarks: '',
      },
      editFormRules: {
        name: [{ trigger: "blur", validator: validateName }],
        sort: [{ trigger: "blur", validator: validateSort }]
      },
      addFormRules:{
        name: [{ trigger: "blur", validator: validateName }],
        sort: [{ trigger: "blur", validator: validateSort }]
      },
      editLoading: false,
      deleteDialog: false,
      deleteId: '',
      deleteInfo: '',
    };
  },
  computed:{
    ...mapGetters(['clientData','isRefreshData'])
  },
  watch:{
    async isRefreshData(newValue){
      if(newValue){
        await this.getGroupList()
        this.$store.commit('index/SET_ISREFRESH',false)
        this.$message({
          type: 'success',
          message: '数据刷新成功'
        })
      }
    }
  },
  created(){
    this.getGroupList()
  },
  methods: {
    search(form){
      if(form.name === ''){
        this.getGroupList()
      }else {
        let fuse = new Fuse(this.tableData, {
          shouldSort: true,
          threshold: 0.4,
          location: 0,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 1,
          keys: [{
            name: 'name',
            weight: 0.7
          }]
        });
        this.tableData = fuse.search(form.name).map(item => {
          return item.item
        })
      }
    },
    resetForm(form){
      this.getGroupList()
      Object.keys(form).forEach(item => {
        form[item] = ''
      })
    },
    getGroupList(){
      return new Promise((resolve,reject) => {
        groupList().then(res => {
          this.loadingShow = true
          let {result} = res
          let data = this.$rsaDecrypt(result)
          this.tableData = data.lists
          this.loadingShow = false
          resolve(data)
        }).catch(err => {
          reject(err)
          this.loadingShow = false
        })
      })
    },
    
    edit(row) {
      this.editDialog = true;
      Object.keys(this.editForm).forEach(item => {
        this.editForm[item] = row[item]
      })
    },
    async deleteGroup() {
      try{
        let requestData = this.$rsaEncryptForm({id:this.deleteId}, "SIJa%$%3hu12hu(*1278")
        let res = await groupDelete(requestData)
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.deleteDialog = false
        this.getGroupList()
      }catch(err){
        console.log(err)
      }
    },
    submitEdit(){
      this.$refs.editForm.validate(async (valid) => {
        if(valid){
          try{
            this.editLoading = true
            let requestData = this.$rsaEncryptForm(this.editForm, "SIJa%$%3hu12hu(*1278")
            let res = await groupEdit(requestData)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.editLoading = false
            this.editDialog = false;
            this.getGroupList()
          }catch(err){
            console.log(err)
            this.editLoading = false
          }
        }
      })
    },
    createGroup(){
      this.$refs.addForm.validate(async (valid) => {
        if(valid){
          try{
            this.editLoading = true
            let requestData = this.$rsaEncryptForm(this.addForm, "SIJa%$%3hu12hu(*1278")
            let res = await groupAdd(requestData)
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.addDialog = false;
            this.editLoading = false
            this.getGroupList()
          }catch(err){
            console.log(err)
            this.editLoading = false
          }
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common.scss';

svg {
  font-size: 18px;
  margin-right: 20px;
  color: #ff6e00;
  cursor: pointer;
}




::v-deep .el-dialog {
  border-radius: 10px;
  .el-dialog__header {
    padding: 54px 20px 0px 70px;
  }
  .el-dialog__body {
    padding: 0 70px 60px 70px;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    p {
      margin-bottom: 36px;
    }
  }
}
.freed-title{
  font-size: 20px;
  color: #ff6e00;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  i{
    padding-right: 10px;
  }
}

</style>